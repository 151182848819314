<template>
  <div
    class="quick-viewQuantity"
  >
    <p class="quantity-text">
      {{ language.SHEIN_KEY_PC_14601 }}:
    </p>
    <div
      class="quantity-box"
      da-event-click="1-8-6-54"
    >
      <span
        class="reduce"
        :class="[quantity <= 1 ? 'disabled' : '']"
        @click="reduceQty"
      >
        <i
          :class="[
            'svgicon',
            quantity <= 1 ? 'svgicon-reduce-disabled' : 'svgicon-reduce',
          ]"
        >
        </i>
      </span>
      <input
        :value="value"
        type="text"
        :disabled="addCartUpperLimit === 0"
        @input="handleQtyInput"
        @keypress="handleQtyKeypress"
      />
      <span
        class="add"
        :class="[addCartUpperLimit <= value ? 'disabled' : '']"
        @click="addQty"
      >
        <i
          :class="[
            'svgicon',
            addCartUpperLimit <= value ? 'svgicon-add-disabled' : 'svgicon-add',
          ]"
        >
        </i>
      </span>
    </div>

    <!-- 库存少提示 -->
    <div class="quick-viewQuantity__tips">
      <div
        v-if="buyMultipleText"
        class="quick-viewQuantity__buyMultipleText"
      >
        <Icon
          name="sui_icon_activity_12px_1"
          size="12px"
          style="margin-right: 2px;"
        />
        {{ buyMultipleText }}
      </div>
      <div
        v-if="buyMultipleText && showStockTips"
        class="quick-viewQuantity__gap"
      ></div>
      <div
        v-if="showStockTips"
        v-expose="{
          id: '1-8-6-239',
          data: {
            goods_level: currentMall?.sku_large_ship !== undefined ? 'sku' : 'skc',
            label_type: currentMall?.is_absolute_low_inventory ? 1 : 2,
            location: 'qty',
          },
        }"
        class="quick-viewQuantity__qty-orange"
      >
        <Icon
          name="sui_icon_hourglass_12px_1"
          size="12px"
          style="margin-right: 2px;"
        />
        {{ stockTip }}
      </div>
    </div>
  </div>
</template>

<script>
import 'public/src/icon/add.svg'
import 'public/src/icon/add-disabled.svg'
import 'public/src/icon/reduce.svg'
import 'public/src/icon/reduce-disabled.svg'
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'QuickViewQuantity',
  components: { Icon },
  props: {
    goodsData: {
      type: Object,
      required: true,
    },
    promotionInfo: {
      type: Array,
      default: () => ([])
    },
    quantity: {
      type: [Number, String],
      required: true,
      default: 1,
    },
    skuInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    skuCode: {
      type: [String, Number],
      required: true,
      default: '',
    },
    quickViewConfig: {
      type: Object,
      default() {
        return {}
      },
    },
    language: {
      type: Object,
      required: true,
      default: () => ({})
    },
    showTips: {
      type: Boolean,
      default: true,
    },
    getEstimatedInfo: {
      type: Object,
      default: () => ({})
    },
    screenAbtConfig: {
      type: Object,
      default: () => ({})
    },
    currentMall: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['changeQuantity'],
  data() {
    return {
      value: 1,
    }
  },
  computed: {
    mallCode() {
      const { goodsData } = this
      return goodsData?.mallCode || ''
    },
    stock() {
      const { goodsData, mallCode, skuCode, skuInfo } = this
      if (!goodsData) {
        return 0
      }
      const currentStock =
        goodsData?.detail?.is_on_sale == 0
          ? 0
          : Number(goodsData?.detail?.mall?.[mallCode]?.stock || 0)
      return skuCode
        ? skuInfo?.mall?.[mallCode]?.stock || 0
        : currentStock
    },
    // 闪购加车上限
    flashSaleLimit() {
      const flashSaleData = this.promotionInfo?.find(i => i.typeId == 10)
      if (!flashSaleData) {
        return 100000
      }
      const currentTime = Date.now() / 1000
      const {
        singleLimitType,
        buyLimit,
        soldNum,
        endTimestamp,
        singleNum
      } = flashSaleData
      const hasLimit = (singleLimitType == 1 || singleLimitType == 3)
      return hasLimit &&
        Number(buyLimit) > Number(soldNum) &&
        Number(currentTime) < Number(endTimestamp)
        ? singleNum
        : 100000
    },
    // 一口价加车上限
    underPriceLimit() {
      const { goodsData, mallCode } = this
      const underPriceInfo = goodsData?.detail?.mall?.[mallCode]
        ?.promotionInfo?.find(i => i.typeId == 30)
      const limitNum = Number(underPriceInfo?.singleNum)
      if (isNaN(limitNum) || !limitNum) {
        return 100000
      }
      return limitNum
    },
    // 加车上限
    addCartUpperLimit() {
      const { stock, flashSaleLimit, underPriceLimit } = this
      return Math.min(stock, flashSaleLimit, underPriceLimit)
    },
    stockTip() {
      const { stock, is_absolute_low_inventory, is_relatively_low_inventory } = this.currentMall || {}
      if(stock <= 0) {
        // 无库存不展示提示
        return ''
      }else if(is_absolute_low_inventory === true) {
        return this.replaceTemplate(stock, this.language.SHEIN_KEY_PC_16400)
      }else if(is_relatively_low_inventory === true) {
        return this.language.SHEIN_KEY_PC_16990
      }
      return ''
    },
    showStockTips() {
      let { showTips, currentMall } = this
      let judge
      const { stock, is_absolute_low_inventory, is_relatively_low_inventory } = currentMall || {}
      if (stock <= 0) {
        judge = false
      } else if (is_absolute_low_inventory || is_relatively_low_inventory) {
        judge = true
      }
      return showTips && judge
    },
    buyMultipleText() {
      // 当数量选择器的数量小于等于n件到手价的n，展示文案为 Buy {0} save {1}， {0}取n件到手价的n，{1}取买n件时整体的减免金额；
      // 当数量选择器的数量大于n件到手价的n,展示文案为 Buy more save more
      let { language, getEstimatedInfo } = this
      let { isSatisfiedBuyMultiple, buyMultiplePic, isSatisfiedBuyMultipleFromPromotion, isSatisfiedBuyMultipleFromCoupon, optimalCoupon, mulSaleInfo } = getEstimatedInfo || {}
      if (!isSatisfiedBuyMultiple) return null
      if (this.value <= buyMultiplePic) {
        if (isSatisfiedBuyMultipleFromPromotion) {
          return template(
            buyMultiplePic,
            mulSaleInfo?.retail_discount_price?.amountWithSymbol,
            language.SHEIN_KEY_PC_31387 || 'Buy {0} save {1}'
          )
        }
        if (isSatisfiedBuyMultipleFromCoupon) {
          return template(
            buyMultiplePic,
            optimalCoupon?.retail_coupon_discount_price?.amountWithSymbol,
            language.SHEIN_KEY_PC_31387 || 'Buy {0} save {1}'
          )
        }
      }
      return language.SHEIN_KEY_PWA_31774 || 'Buy more save more'
    }
  },
  watch: {
    quantity: {
      immediate: true,
      handler(val) {
        this.value = val
      },
    },
    addCartUpperLimit: {
      handler(limit) {
        const { quantity } = this
        const quantityNum = Number(quantity)
        const limitNum = Number(limit)
        if (quantityNum > limitNum) {
          this.$emit('changeQuantity', limit)
        }  else if (quantityNum <= 1 && limitNum > 0) {
          this.$emit('changeQuantity', 1)
        }
      }
    }
  },
  methods: {
    replaceTemplate: template,
    addQty() {
      const { addCartUpperLimit } = this
      let quantity = Number(this.quantity)
      if (quantity < addCartUpperLimit) {
        this.$emit('changeQuantity', quantity + 1)
      }
    },
    reduceQty() {
      let quantity = Number(this.quantity)
      if (quantity <= 1) {
        return
      }
      this.$emit('changeQuantity', quantity - 1)

    },
    handleQtyKeypress(e) {
      if (e.keyCode < 48 || e.keyCode > 57) {
        e.preventDefault()
        return
      }
    },
    handleQtyInput(e) {
      const { addCartUpperLimit } = this
      this.value = e.target.value
      let val = parseInt(e.target.value)
      val = val
        ? val < addCartUpperLimit
          ? val
          : addCartUpperLimit
            ? addCartUpperLimit
            : 1
        : 1
      this.$nextTick(() => {
        this.value = val
      })
      this.$emit('changeQuantity', val)
    },
  }
}
</script>

<style lang="less" scoped>
.quick-viewQuantity {
  display: flex;
  align-items: center;

  .quantity-text {
    font-size: 14px;
    color: #222;
  }

  .quantity-box {
    border: 1px solid #f6f6f6;
    margin: 0 15px;
    display: inline-flex;
    align-items: center;

    span, input {
      display: inline-block;
      height: 24px;
      line-height: 24px;
      text-align: center;
    }
    span {
      width: 26px;
      background: #f7f6f7;
      cursor: pointer;
    }
    input {
      width: 50px;
      outline: 0;
      font-size: 16px;
      font-weight: 700;
      border: none;
      background: #fff;
    }
  }
  &__tips {
    display: flex;
    flex-direction: column;
  }
  &__buyMultipleText {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    color: @sui_color_highlight;
  }
  &__gap {
    height: 2px;
  }
  &__qty-orange {
    display: flex;
    align-items: center;
    color: @sui_color_highlight;
    font-size: 12px;
    font-weight: normal;
  }
}
</style>
