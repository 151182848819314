<template>
  <div
    v-if="showPrime"
    class="paid-vip-estimated"
    :class="[vipEstimatedClass]"
    @click="jump"
  >
    <div
      v-if="!(vipEstimatedNew1 || vipEstimatedNew2)"
      class="paid-vip-estimated__bg"
      :style="{
        backgroundImage: 'url(' + cdnUrl + '/she_dist/images/sui_img_Background_color-ac33845bd6.png)'
      }"
    ></div>
    <div class="paid-vip-estimated__content">
      <div
        class="paid-vip-estimated__info"
      >
        <img
          class="paid-vip-estimated__img"
          :src="`${cdnUrl}/she_dist/images/${vipEstimatedPath}.png`"
          alt="shein club logo"
        />
        <p
          class="paid-vip-estimated__content-text"
          v-html="text"
        >
        </p>
      </div>
      <Icon
        name="sui_icon_more_right_18px"
        size="12"
        color="#873C00"
        class="paid-vip-estimated__icon"
      />
    </div>
  </div>
</template>
<script>
import schttp from 'public/src/services/schttp'
import { PromotionUtils } from 'public/src/pages/common/promotion/utils'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import { Icon } from '@shein-aidc/icon-vue3'

daEventCenter.addSubscriber({ modulecode: '1-8-6' })
export default {
  components: { Icon },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    paidVipData: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    source: {
      type: String,
      default: ''
    },
    priceDetect: {
      type: Boolean,
      default: false
    },
    showSheinClub: {
      type: Boolean,
      default: false
    },
    isPaidUser: {
      type: Boolean,
      default: false
    },
    isFreeReceiveUser: {
      type: Boolean,
      default: false
    },
    showClubType: {
      type: Boolean,
      default: false
    },
    // cdnUrl
    cdnUrl: {
      type: String,
      default: ''
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    vipEstimatedClass: {
      type: String,
      default: ''
    },
    detailSheinClubContent: {
      type: String,
      default: ''
    },
    isEstimatedAntiContent: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    goodsId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      prime_trial_type: '',
      updateIsPaidUser: false // 因为服务端缓存3分钟，所以登陆后需要重新获取一下是否是付费会员
    }
  },
  computed: {
    vipEstimatedNew1() {
      return this.vipEstimatedClass === 'New1'
    },
    vipEstimatedNew2() {
      return this.vipEstimatedClass === 'New2'
    },
    vipEstimatedPath() {
      if(this.vipEstimatedClass === 'New1'){
        return 'product_detail/vip-estimated-new1-1cfbf98e5b'
      }else if(this.vipEstimatedClass === 'New2'){
        return 'product_detail/vip-estimated-new2-fe671c6b91'
      }else {
        return 'pay_vip/pay_vip_member_name@5x-9b50cbcf29'
      }
    },
    showPrime() {
      return this.price && (this.showSheinClub || this.isSheinClubUser)
    },
    price() {
      const price = this.data?.price?.amountWithSymbol
      return price
    },
    showFrom() {
      // 有最低价 并且没有选中
      return !!(this.priceDetect && !this.data?.sku_code)
    },
    isSheinClubUser() {
      return this.isPaidUser || this.updateIsPaidUser
    },
    analysisData() {
      const { paidInfo, source, prime_trial_type, goodsId, detailSheinClubContent, isEstimatedAntiContent } = this
      return this.formatData({
        ...paidInfo,
        location: source === 'quickview' ? 'popup' : 'page',
        goods_id: goodsId,
        prime_trial_type,
        labeltype: detailSheinClubContent ? `goodsDetail|${isEstimatedAntiContent ? 'estimated_anti' : 'estimated_normal'}` : ''
      })
    }
  },
  watch: {
    data(newVal) {
      if (newVal && newVal.skc && this.showPrime) {
        this.expose()
      }
    }
  },
  async mounted() {
    const [
      primeUserStatus,
      primeUserDetail,
      primeFreeTrial
    ] = await Promise.all([
      this.getPrimeUserInfo(),
      this.getPrimeUserDetail(),
      this.getFreeTrial(),
    ])
    const discount_price =
      primeUserDetail?.info?.discount_summary_info?.discount_price || 0
    this.paidInfo = {
      prime_level: primeUserDetail?.info?.product_info?.product_cycle_type || 0,
      total_saving: discount_price.toFixed(2)
    }
    // 已开通不上报
    // 前端未开通且引导文案为试用文案，则上报1
    // 前端未开通且引导文案为历史文案，则上报0
    if(!primeUserDetail.info?.product_info?.product_cycle_type) {
      this.prime_trial_type = 0
      if(this.isFreeReceiveUser && this.showClubType) {
        this.prime_trial_type = 1
      }
    }
    this.updateIsPaidUser = primeUserStatus

    this.$emit('getSheinClubUserInfo', {
      isSheinClubUser: this.isSheinClubUser,
      ...this.paidInfo
    })
    this.$emit('getIsFreeTrial', primeFreeTrial)
    if (this.showPrime) {
      this.expose()
    }
  },
  methods: {
    expose() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-98',
        extraData: this.analysisData
      })
    },
    jump() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-99',
        extraData: this.analysisData
      })
      let source = this.source
      if(source === 'quickview') {
        const pageName = window?.SaPageInfo?.page_name ?? ''
        source = pageName.replace('page_', '') + '_' + source
      }

      if (this.isSheinClubUser) {
        PromotionUtils.jumpToAdd(this.paidVipData, {
          source: source,
          scence: 'paidVip'
        })
      } else if(this.isFreeReceiveUser && this.showClubType){
        window.location.href = '/user/primefreetrial?productsource=' + source
      }  else {
        window.location.href = '/user/prime?productsource=' + source
      }
    },
    async getPrimeUserInfo() {
      const isLogin = isLoginFn()
      if (!isLogin) {
        sessionStorage.removeItem('_GET_SHEIN_CLUB_USER')
        sessionStorage.removeItem('_IS_SHEIN_CLUB_USER')
      }
      if(this.isSheinClubUser) return false
      let status = false
      if (isLogin) {
        const getSheinClubUser = sessionStorage.getItem('_GET_SHEIN_CLUB_USER')
        const cacheIsSheinClubUser = sessionStorage.getItem('_IS_SHEIN_CLUB_USER')
        if (cacheIsSheinClubUser) {
          status = Number(cacheIsSheinClubUser) === 1
        } else if (!getSheinClubUser) {
          const res = await schttp({
            url: '/api/prime/postQueryMemberInfo/get',
            method: 'POST'
          })
          status = Number(res?.info?.is_paid) === 1
          sessionStorage.setItem('_IS_SHEIN_CLUB_USER', res?.info?.is_paid)
          sessionStorage.setItem('_GET_SHEIN_CLUB_USER', true) // 降低qps
        } 
      }
      return status
    },
    async getPrimeUserDetail() {
      const res = await schttp({
        url: '/api/prime/postPaidDetail/get?money=USD',
        method: 'POST'
      })
      return res
    },
    async getFreeTrial() {
      const isLogin = isLoginFn()
      if(isLogin){
        const res = await schttp({
          url: '/api/prime/postCheckFreeTrial/get',
          method: 'POST'
        })
        return res?.info?.can_trial === 1 || false
      }
      return false
    },
    formatData(data = {}) {
      let res = {}
      for(let key in data) {
        if(data[key] !== '' && data[key] !== undefined) {
          res[key] = data[key]
        }
      }
      return res
    }
  },
  emits: ['getSheinClubUserInfo', 'getIsFreeTrial']
}
</script>
<style lang="less">
.paid-vip-estimated {
  position: relative;
  height: 32px;
  background: #fff0e8;
  border-radius: 1px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  &__label {
    display: flex;
    align-items: center;
  }
  &__bg {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-size: 100% 44px;
    background-repeat: no-repeat;
  }
  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    overflow: hidden;
  }
  &__content-text {
    font-size: 12px;
    font-weight: 400;
    color: #873C00;
    padding: 7px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__icon {
    transform: rotate(0deg) /* rtl:rotate(180deg) */;
  }
  &__info {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  &__img{
    width: 94px;
  }
}

.New1.paid-vip-estimated{
  background: linear-gradient(0deg, rgba(255, 226, 211, 0.70) 0%, rgba(255, 226, 211, 0.70) 100%), #FFF;
}
.New1 .paid-vip-estimated{
  &__content{
    padding: 6px;
  }
  &__img{
    width: auto;
    height: 18px;
  }
  &__content-text{
    padding: 0 8px;
  }
}

.New2.paid-vip-estimated{
  background: linear-gradient(0deg, rgba(255, 226, 211, 0.90) 0%, rgba(255, 226, 211, 0.90) 100%), #FFF;
}
.New2 .paid-vip-estimated{
  &__content{
    padding: 7px 8px;
  }
  &__img{
    width: auto;
    height: 16px;
  }
  &__content-text{
    padding: 0 8px;
  }

}

.New1.paid-vip-estimated,
.New2.paid-vip-estimated{
  height: 30px;
  font-size: 12px;
  border-radius: 2px;
}

</style>
