<template>
  <button
    v-show="show"
    v-expose="{
      id: '1-8-6-132',
      data: exposeData
    }"
    class="product-intro__one-click-pay goodsDetail-btn-xl she-btn-black fsp-element"
    @click="handleClick"
  >
    <!-- 插槽 -->
    <slot></slot>
    <div class="goodsDetail-btn-xl__container">
      <template v-if="oneClickPayLoading">
        <div class="la-ball-pulse la-ball-pulse-white">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </template>
      <template v-else>
        <span class="product-intro__one-click-pay-txt">{{ language.SHEIN_KEY_PC_25005 }}</span>
        <span
          v-if="isShowCountdown"
          class="product-intro__one-click-pay-count not-fsp-element"
          :class="{'color-g': countDownAbt === 'showgreen'}"
        >{{ countDownStr }}</span>
      </template>
    </div>
  </button>

  <!-- 一键购成功对话框 -->
  <OrderDialog
    v-if="relativeOrdersShow"
    :visible="orderDialogVisible"
    :relative-orders="relativeOrders"
    :current-order-num="billNo"
    :language="language"
    @close="closeDialog"
  />
</template>
<script setup>
import { ref, toRefs, defineEmits, computed, withDirectives, onMounted, onUnmounted, watch } from 'vue'
import OneClickPay from 'public/src/pages/common/oneClickPay'
import OrderDialog from './OrderDialog.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { CountDown } from '@shein/common-function'
// import schttp from 'public/src/services/schttp'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

const { currency } = gbCommonInfo

withDirectives({ expose })
const emit = defineEmits(['paySuccess', 'payFail', 'transComplete'])

const props = defineProps({
  // 是否展示
  show: {
    type: Boolean,
    default: false,
  },
  // 场景
  scene: {
    type: String,
    default: 'ProductDetail',
  },
  // 一键购订单信息
  billInfo: {
    type: Object,
    default: () => ({})
  },
  // 商品详情
  goodsData: {
    type: Object,
    default: () => ({})
  },
  // 加购数量
  quantity: {
    type: Number,
    default: 1,
  },
  // 一键购的额外请求参数
  extraPayParams: {
    type: Object,
    default: () => ({})
  },
  // 点击时请求前进行的校验
  beforePay: {
    type: Function,
    default: () => (() => true)
  },
  language: {
    type: Object,
    default: () => ({})
  },
  // 一键购点击的埋点数据
  analysisData: {
    type: Object,
    default: () => ({})
  },
})
const {
  show,
  scene,
  billInfo,
  goodsData,
  quantity,
  extraPayParams,
  beforePay,
  language,
  analysisData,
} = toRefs(props)

const oneClickPayLoading = ref(false)
const orderDialogVisible = ref(false)
const relativeOrders = ref([])

const billNo = computed(() => {
  return billInfo.value?.billno || ''
})

const relativeOrdersShow = computed(() => {
  return scene.value === 'ProductDetail'
})

const closeDialog = () => {
  orderDialogVisible.value = false
}

const handleClick = () => {
  const currentBillInfo = { ...(billInfo.value || {}) }
  const currentGoodsData = { ...(goodsData.value || {}) }
  if (oneClickPayLoading.value || !currentBillInfo.billno) {
    return
  }

  if (beforePay.value instanceof Function) {
    const testResult = beforePay.value()
    if (!testResult) {
      daEventCenter.triggerNotice({
        daId: '1-8-6-133',
        extraData: { ...exposeData.value },
      })
      return
    }
  }

  // 构造请求参数
  const { payment_code, billno, sign_up_email, show_card_no, country_code, pay_no } = currentBillInfo
  const { goods_id, goods_img, goods_sn, business_model } = currentGoodsData
  const payParams = {
    goods_id,
    good_sn: goods_sn,
    business_model,
    payment_code: payment_code,
    billno,
    sign_up_email,
    show_card_no,
    quantity: quantity.value,
    currency,
    country_code,
    goods_thumb: goods_img,
    payment_no: pay_no,
    ...(extraPayParams.value || {}),
  }
  
  daEventCenter.triggerNotice({
    daId: '1-8-6-133',
    extraData: { ...analysisData.value },
  })

  oneClickPayLoading.value = true
  OneClickPay.create({
    options: {
      location: analysisData.value?.location || '',
      scene: scene.value
    },
    params: payParams,
    // 支付成功，通知更新一键购状态
    onSuccess(successInfo) {
      oneClickPayLoading.value = false
      emit(
        'paySuccess',
        { ...(successInfo || {}), reqParams: { ...payParams } }
      )
      // daEventCenter.triggerNotice({
      //   daId: '1-8-6-133',
      //   extraData: {
      //     ...analysisData.value,
      //     result: 1,
      //     result_reason: 0,
      //     result_order: 1,
      //   },
      // })
    },
    // 支付接口报错或前端必要参数校验失败，更新状态并自动加购
    onFail(failInfo) {
      oneClickPayLoading.value = false
      emit(
        'payFail',
        { ...(failInfo || {}), reqParams: { ...payParams } },
      )
      // daEventCenter.triggerNotice({
      //   daId: '1-8-6-133',
      //   extraData: {
      //     ...analysisData.value,
      //     result: 2,
      //     result_reason: failInfo.code || '',
      //     result_order: !!failInfo?.failScene ? 2 : 1,
      //   },
      // })
    },
    // 支付事务和交互完全结束
    onComplete({ type, extraData = {} } = {}) {
      if (type === 'success') {
        const { addPurchaseRelation } = extraData
        const { all_relation_order_list } = addPurchaseRelation || {}
        if (Array.isArray(all_relation_order_list)) {
          relativeOrders.value = all_relation_order_list
        }
        emit('transComplete', 'success', extraData)
        orderDialogVisible.value = true
      }
      if (type === 'fail') {
        emit('transComplete', 'fail', extraData)
      }

      // 兜底
      oneClickPayLoading.value = false
    }
  })
}

// 倒计时相关⬇
const timer = ref(null)
// const countDown = ref(0)
const countDown = computed(() => billInfo.value?.oneClickPayOverTime || 0)
const countDownStr = ref('')
const countDownAbt = ref('')
// 是否展示倒计时(abt命中 && countDown>0)
const isShowCountdown = computed(() => {
  const showList = ['showgreen', 'show']
  if(!showList.includes(countDownAbt.value)) return false
  if(countDown.value === 0) return false
  return true
}) 

const exposeData = computed(() => {
  return {
    ...analysisData.value,
    deadline: +isShowCountdown.value
  }
})

const countDownFn = () => {
  timer.value = new CountDown({
    seconds: countDown.value,
    countFunc(tiemObj){
      const { H, M, S } = tiemObj
      countDownStr.value = `${H}:${M}:${S}`
    },
    endFunc() {
      countDown.value = 0
    }
  })
  timer.value.start()
}

// const fetchCountryData = () => {
//   const currentGoodsData = { ...(goodsData.value || {}) }
//   const { goods_id, goods_sn } = currentGoodsData
//   return new Promise((resolve) => {
//     schttp({
//       method: 'POST',
//       url: '/api/ocp/checkQualificationForSimple/get',
//       data: {
//         good_id: goods_id,
//         good_sn: goods_sn, 
//         billno: billNo.value
//       }
//     }).then((res) => {
//       countDown.value = res.checkSimple?.info?.oneClickPayOverTime ?? 0
//       resolve()
//     })
//   })
// }

onMounted(async() => {
  const abtInfo = await getUserAbtData()
  countDownAbt.value = abtInfo?.detailcountdown?.param?.detailcountdown
  // await fetchCountryData()
  countDownFn()
})
onUnmounted(() => {
  timer.value?.clear()
})

watch(
  () => billInfo.value, 
  (val) => {
    if(val){
      countDownFn()
    }
  })
</script>
<style lang="less">
@import "../../style/common/index.less";

.product-intro__one-click-pay {
  position: relative;
  margin-right: 12px !important;
  text-align: center;
  width: calc(50% - 6px);
  min-width: unset;
  font-size: 12px !important;
  padding: 0 12px;

  &.goodsDetail-btn-xl {
    min-width: unset;
    line-height: normal;
  }
  .goodsDetail-btn-xl__container {
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-txt {
    font-size: 14px;
    line-height: 26px;
  }
  &-count {
    display: block;
    font-size: 12px;
    font-weight: 700;
    font-family: Arial;
  }
  .color-g {
    color: #2BB078;
  }
}
</style>
